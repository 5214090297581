import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { exportExcel } from "./components/export";
import { ClientsContext } from "../contexts/Context";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

export default function Import() {
  const navigate = useNavigate();
  const context = useContext(ClientsContext);
  const [clients, setClients] = useState<any>(null);
  const [clicked, setClicked] = useState(false);

  function handleImport(e: any) {
    const file: any = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    if (
      file &&
      !(
        file.name.endsWith(".csv") ||
        file.name.endsWith(".xls") ||
        file.name.endsWith(".xlsx") ||
        file.name.endsWith(".xlsm")
      )
    )
      return alert("Por favor escolha um ficheiro com formato excel.");
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const arrayBuffer = e.target?.result as ArrayBuffer;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        let jsonData = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          dateNF: "dd/mm/yyyy, hh:mm",
        });
        // Trim whitespace to avoid filter issues
        jsonData = jsonData.map((row: any) => {
          return Object.keys(row).reduce((trimmedRow: any, key) => {
            let value = row[key];
            if (typeof value !== "string") {
              alert(
                "Todos os valores do excel devem estar no formato de texto."
              );
              return null;
            }
            // Check if the value is a date, if so, format it
            if (
              value.split("/").length > 2 &&
              value.includes(":") &&
              !value.includes(",")
            ) {
              value = value.trim().replace(/ /g, ", ");
            }
            trimmedRow[key.trim()] = value.trim();
            return trimmedRow;
          }, {});
        });
        if (!checkData(jsonData))
          return alert("Foi encontrado um erro em uma reserva do ficheiro.");
        setClients(jsonData);
      } catch (error) {
        console.error("Erro ao processar o ficheiro:", error);
        alert("Erro ao processar o ficheiro!");
      }
    };
    reader.onerror = (error) => {
      console.error("Erro ao ler o ficheiro:", error);
      alert("Erro ao ler o ficheiro!");
    };
    reader.readAsArrayBuffer(file);
  }

  function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function decapitalize(str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  function checkData(data: any) {
    for (let i = 0; i < data.length; i++) {
      const client = data[i];
      let invalidField = "";

      if (!client?.name) {
        invalidField = "Name";
      }

      if (!client?.lastname) {
        invalidField = "Lastname";
      }

      if (!client?.phoneNumber) {
        invalidField = "PhoneNumber";
      }

      if (invalidField) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "${invalidField}" é obrigatório.`
        );
        return false;
      }

      if (!client?.licensePlate) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "LicensePlate" é obrigatório.`
        );
        return false;
      }

      if (!client?.checkIn) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "CheckIn" é obrigatório.`
        );
        return false;
      }

      if (!client?.checkIn.includes("/")) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "CheckIn" deve estar no formato dd/mm/yyyy, hh:mm.`
        );
        return false;
      }

      if (!client?.checkOut) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "CheckOut" é obrigatório.`
        );
        return false;
      }

      if (!client?.checkOut.includes("/")) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "CheckOut" deve estar no formato dd/mm/yyyy, hh:mm.`
        );
        return false;
      }

      if (client?.parkBrand?.toLowerCase() !== "skypark" &&
        client?.parkBrand?.toLowerCase() !== "airpark" &&
        client?.parkBrand?.toLowerCase() !== "redpark") {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "ParkBrand" é obrigatório e deve ser 'skypark', 'airpark' ou 'redpark'.`
        );
        return false;
      }

      if (!client?.deliveryName) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "DeliveryName" é obrigatório.`
        );
        return false;
      }

      if (!capitalize(client?.parkBrand.trim())) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "ParkBrand" deve começar com letra maiúscula.`
        );
        return false;
      }

      if (
        !(
          capitalize(client?.parkingType.trim()) === "Coberto" ||
          capitalize(client?.parkingType.trim()) === "Descoberto" ||
          capitalize(client?.parkingType.trim()) === "Indoor"
        )
      ) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "ParkingType" deve ser "Coberto", "Descoberto" ou "Indoor".`
        );
        return false;
      }

      if (
        parseInt(client?.parkingPrice) < 0 ||
        isNaN(parseInt(client?.parkingPrice))
      ) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "ParkingPrice" deve ser um número positivo.`
        );
        return false;
      }

      if (
        parseInt(client?.deliveryPrice) < 0 ||
        isNaN(parseInt(client?.deliveryPrice))
      ) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "DeliveryPrice" deve ser um número positivo.`
        );
        return false;
      }

      if (
        parseInt(client?.bookingPrice) < 0 ||
        isNaN(parseInt(client?.bookingPrice))
      ) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "BookingPrice" deve ser um número positivo.`
        );
        return false;
      }

      if (
        !client?.stats ||
        (decapitalize(client?.stats) !== "reservado" &&
          decapitalize(client?.stats) !== "recolhido")
      ) {
        console.error("Erro na reserva:", client);
        alert(
          `Erro na reserva do cliente número ${
            i + 1
          }. O campo "Stats" é obrigatório e deve ser "reservado" ou "recolhido"`
        );
        return false;
      }

    }
    return true;
  }

  function handleExport() {
    exportExcel(
      [
        {
          name: "Rafael",
          lastname: "Coias",
          email: "teste@teste.com",
          phoneNumber: "912345678",
          carInfo: "Mercedes Benz C220 Branco",
          licensePlate: "AA33TT",
          returnFlight: "TP1234",
          parkBrand: "skypark",
          parkingType: "Descoberto",
          parkingPrice: "5",
          extraServices: "Lavagem, Seguro",
          bookingPrice: "30",
          checkIn: "14/03/2024, 20:55",
          checkOut: "14/03/2024, 20:55",
          deliveryName: "Terminal 1",
          deliveryPrice: 10,
          park: "airpark:coberto",
          alocation: "1701",
          stats: "recolhido",
          hasOnlinePayment: "yes | sim",
        },
      ],
      "template"
    );
  }

  function formateDate(isoDateString: string) {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    if (day === "NaN") return "Campo vazio";
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  }

  function getDate(dateStr: string): string {
    const normalizedDateStr = dateStr.replace(",", "");
    const [datePart, timePart] = normalizedDateStr.split(" ");
    let [day, month, year] = datePart.split("/");
    if (year.length === 2) {
      year = `20${year}`;
    }
    return `${day}/${month}/${year}, ${timePart}`;
  }

  function getExtraServices(services: string[]) {
    return services.map((service) => {
      return {
        pt: capitalize(service.trim()),
        en: "",
        price: 0,
        type: 0,
        checked: false,
      };
    });
  }

  const checkIfThereIsSameBooking = async (client: any) => {
    const q = query(
      collection(db, `${client?.city?.toLowerCase()}/${client?.parkBrand?.toLowerCase()}/clients`),
      where("email", "==", client.email),
      where("licensePlate", "==", client.licensePlate),
      where("checkIn", "==", client.checkIn),
      where("checkOut", "==", client.checkOut),
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.size > 0) {
      return true;
    }
    return false;
  };

  async function add() {
    if (!clients) return;
    const countParkBrands: any = {};
    try {
      const duplicatedClients = [];
      const promises = clients.map(async (client: any) => {
        if (countParkBrands[client.parkBrand.toLowerCase()]) {
          countParkBrands[client.parkBrand.toLowerCase()] += 1;
        } else {
          countParkBrands[client.parkBrand.toLowerCase()] = 1;
        }
        try {
          const newClient = {
            stats: client?.stats
              ? decapitalize(client?.stats.trim())
              : "reservado",
            name: capitalize(client.name?.trim()) || "",
            lastname: capitalize(client.lastname?.trim()) || "",
            email: client.email?.trim() || "",
            phoneNumber: client.phoneNumber?.trim() || "",
            carInfo: client.carInfo?.trim() || "",
            licensePlate: client.licensePlate?.trim() || "",
            parkBrand: client.parkBrand
              ? capitalize(client.parkBrand?.trim())
              : "",
            parkingType: client.parkingType
              ? capitalize(client.parkingType?.trim())
              : "",
            parkingPrice: client.parkingPrice?.trim() || "",
            deliveryPrice: client.deliveryPrice?.trim() || "",
            bookingPrice: client.bookingPrice?.trim() || "",
            bookingDate:
              client.bookingDate || formateDate(new Date().toISOString()) || "",
            checkIn: getDate(client.checkIn?.trim()) || "",
            checkOut: getDate(client.checkOut?.trim()) || "",
            deliveryName: client?.deliveryName
              ? client.deliveryName?.trim()
              : "",
            city: client?.city?.toLowerCase() || context?.user?.selectedCity || "lisbon",
            returnFlight: client.returnFlight?.trim() || "",
            extraServices: client.extraServices
              ? getExtraServices(
                  client.extraServices
                    .split(", ")
                    .map((service: string) => service.trim())
                )
              : [],
            action: "Importar",
            actionDate: formateDate(new Date().toISOString()) || "",
            actionUser: context?.user?.email || "",
            park: client?.park ? client?.park : "",
            imported: true,
            hasOnlinePayment: client.hasOnlinePayment ? (client.hasOnlinePayment.toLowerCase().include("s") || client.hasOnlinePayment.toLowerCase().include("y")) : false,
          };

          // Check duplicated booking
          const bookingMade = await checkIfThereIsSameBooking(newClient);
          if (bookingMade) {
            duplicatedClients.push(newClient);
            return null;
          }

          const clientDocRef = await addDoc(
            collection(
              db,
              `${
                context?.user?.selectedCity
              }/${newClient?.parkBrand.toLowerCase()}/clients`
            ),
            newClient
          );

          const idClient = clientDocRef.id;
          const newHistoryDocRef = doc(
            db,
            `${
              context?.user?.selectedCity
            }/${newClient?.parkBrand.toLowerCase()}/clients/${idClient.toString()}/history`,
            new Date().toISOString()
          );
          await setDoc(newHistoryDocRef, newClient);

          return newClient;
        } catch (error) {
          console.error("Erro ao adicionar cliente:", error);
          alert("Erro ao adicionar reservas!");
        }
      });

      Promise.all(promises)
        .then(() => {
          const totalDoubledFound = duplicatedClients.length;
          const message = totalDoubledFound > 0 ? ` (${totalDoubledFound} duplicadas - não foram criadas)` : "";
          alert("Reservas adicionadas com sucesso! " + message);
          context?.setClients([...context?.clients, ...clients.filter((client:any) => client)]);
          navigate("/home");
        })
        .catch((error) => {
          console.error("Erro ao adicionar clientes:", error);
          alert(error.message);
          navigate("/home");
        });
    } catch (error) {
      console.error("Erro externo ao adicionar clientes:", error);
      alert("Erro ao adicionar reservas.");
    }
  }

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="absolute top-8 left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          IMPORTAR
        </h1>
        <p className="text-center text-[var(--black)] font-mybold">
          NOVAS RESERVAS EM <span className="text-[var(--primary)] underline font-mybold">{context?.user?.selectedCity?.toUpperCase()}</span>
        </p>
        <div className="flex w-full gap-4">
          <button className="w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-white flex items-center justify-center relative">
            IMPORTAR
            <input
              type="file"
              onChange={(e) => handleImport(e)}
              className="absolute top-0 left-0 w-full h-full opacity-0"
            />
          </button>
          <button
            onClick={handleExport}
            className="w-full text-[1prem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-white flex gap-2 items-center justify-center"
          >
            VER EXEMPLO
          </button>
        </div>
        {clients && clients.length > 0 ? (
          <div className="flex flex-col w-full gap-8 text-center">
            <p>{clients.length} reservas encontradas</p>
            <button
              disabled={clicked}
              onClick={() => {
                setClicked(true);
                add();
              }}
              className="w-full text-[1prem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black flex gap-2 items-center justify-center"
            >
              ADICIONAR RESERVAS
            </button>
          </div>
        ) : (
          <p className="text-center">Nenhuma reserva encontrada</p>
        )}
        <div className="flex flex-col gap-2 mt-2">
          <p className="text-[red] font-bold">Campos obrigatórios:</p>
          <p>
            Name, Lastname, PhoneNumber, LicensePlate, CheckIn, CheckOut,
            ParkBrand, ParkingType, ParkingPrice, DeliveryName, DeliveryPrice,
            BookingPrice, Stats
          </p>
        </div>
        <div className="flex flex-col gap-2 mt-2">
          <p className="text-[red] font-bold">Regras do excel:</p>
          <p> - Os valores devem estar no formato de texto</p>
          <p> - stats: 'Recolhido' ou 'Reservado'</p>
          <p> - parkBrand: Marca da reserva (ex. skypark)</p>
          <p> - parkingType: Coberto ou Descoberto</p>
          <p> - parkingPrice: Preço por dia do parque</p>
          <p>
            {" "}
            - extraServices: Lista dos servços separados por uma vírgula *,*
          </p>
          <p> - checkIn: Data e hora do checkIn (ex. 23/03/2024, 13:30)</p>
          <p> - checkOut: Data e hora do checkOut (ex. 23/03/2024, 13:30)</p>
          <p> - deliveryName: Local de entrega do carro (ex. Terminal 1)</p>
          <p> - deliveryPrice: Preço do local de entrega do carro (ex. 10)</p>
          <p> - city: Deve estar em inglês e letras pequenas (ex. lisbon)</p>
        </div>
      </div>
    </>
  );
}
