import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../components/clientCard";
import { updateClient } from "../../server";
import Dialog from "../components/alertDialog";
import { ClientsContext } from "../../contexts/Context";
import emailjs from "emailjs-com";
import Loading from "../components/loading";

export default function Entrega() {
  const { id = 0 } = useParams();
  const navigate = useNavigate();
  const context = useContext(ClientsContext);

  const [listaDePagamento, setListaDePagamento] = useState<any>(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOnClose, setDialogOnClose] = useState("");

  const openDialog = (message: string, onClose: any) => {
    setDialogMessage(message);
    setDialogOpen(true);
    setDialogOnClose(() => onClose);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const [client, setClient] = useState<any>(null);
  const [veiculo, setVeiculo] = useState("");
  const [data, setData] = useState("");
  const [hora, setHora] = useState("");
  const [parque, setParque] = useState("");
  const [condutor, setCondutor] = useState("");
  const [fila, setFila] = useState("");
  const [lugar, setLugar] = useState("");
  const [valor, setValor] = useState("");
  const [pagamento, setPagamento] = useState("");
  const [avaliacao, setAvaliacao] = useState(true);
  const [location, setLocation] = useState<any>(null);

  useEffect(() => {
    async function getClientCard() {
      if (
        !context?.clients ||
        context?.clients.length === 0 ||
        !context?.clients.some(
          (client: any) => client?.idClient === id.toString()
        )
      ) {
        context?.getClients("__name__", [id.toString()]);
        return;
      }
      const data = (context?.clients).filter(
        (client: any) => (client?.idClient).toString() === id.toString()
      );
      if (data.length === 1) setClient(data[0]);
      setListaDePagamento(context?.settings?.tipoDePagamento?.all);
    }
    if (context?.clients && context?.settings) getClientCard();
  }, [id, context?.clients, context?.settings, context]);

  useEffect(() => {
    if (client) {
      setVeiculo(client?.carInfo);
      setParque(client?.park);
      setFila(client?.row);
      setLugar(client?.spot);
      setData(client?.checkOut.split(", ")[0]);
      setHora(client?.checkOut.split(", ")[1]);
      setCondutor(context?.user?.email || "");
      if (client?.hasOnlinePayment) {
        setPagamento("Multibanco");
        setValor(client?.bookingPrice);
      }
      if (localStorage.getItem("formData")) fetchPrevData();
    }
  }, [client, context?.user.email]);

  useEffect(() => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      () => {
        alert("Unable to retrieve your location");
      }
    );
  }, []);

  function fetchPrevData() {
    const data = localStorage.getItem("formData");
    if (data) {
      const parsedData = JSON.parse(data);
      setValor(parsedData?.valor || "");
      setPagamento(parsedData?.pagamento || "");
      setAvaliacao(parsedData?.avaliacao || "");
    }
    localStorage.removeItem("formData");
  }

  function ocorrencia() {
    localStorage.setItem(
      "formData",
      JSON.stringify({
        valor: valor,
        pagamento: pagamento,
        avaliacao: avaliacao,
      })
    );
    navigate(`/ocorrencias/${id}`);
  }

  function isDateOlder(date1Str: string, date2Str: string) {
    const [day1, month1, year1] = date1Str.split(", ")[0].split("/");
    const [day2, month2, year2] = date2Str.split(", ")[0].split("/");

    // Check if the year is two digits long and prepend "20" if it is
    const fullYear1 = year1.length === 2 ? `20${year1}` : year1;
    const fullYear2 = year2.length === 2 ? `20${year2}` : year2;

    const date1: Date = new Date(
      parseInt(fullYear1),
      parseInt(month1) - 1,
      parseInt(day1)
    );
    const date2: Date = new Date(
      parseInt(fullYear2),
      parseInt(month2) - 1,
      parseInt(day2)
    );

    const difference = date1.getTime() - date2.getTime();
    return Math.round(difference / (1000 * 60 * 60 * 24));
  }

  function recalculatePrice() {
    let basePrice = client?.bookingPrice;
    let todaysDate = formatDate(new Date().toISOString()).split(", ")[0];
    let dateDiference = isDateOlder(todaysDate, client?.checkOut);
    if (dateDiference <= 0) return `${basePrice}€`;
    basePrice = parseInt(basePrice) + dateDiference * client?.parkingPrice;
    return `${basePrice}€`;
  }

  function getCurrentHour() {
    const newDate = new Date();
    return (
      newDate.getHours().toString().padStart(2, "0") +
      ":" +
      newDate.getMinutes().toString().padStart(2, "0")
    );
  }

  function formatDate(isoDateString: string) {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  }

  async function entregar(estado: string) {
    // Check inputs
    if (estado === "entregue" && (valor === "" || pagamento === "")) {
      openDialog("Preencha todos os campos.", () => null);
      return;
    }
    // Check if delivery happend
    if (client?.stats === "entregue") {
      openDialog(`Este carro já foi entregue.`, () => null);
      return;
    }

    // Check if valor é um numero e se é maior que 0
    if (
      estado === "entregue" &&
      (isNaN(parseInt(valor)) || parseInt(valor) < 0)
    ) {
      openDialog("Valor inválido.", () => null);
      return;
    }

    const data = { ...client };
    const now = formatDate(new Date().toISOString());
    data["priceOnDelivery"] =
      estado === "em entrega" || estado === "pendente"
        ? client?.bookingPrice
        : valor;
    data["correctedPrice"] =
      estado === "em entrega" || estado === "pendente"
        ? client?.bookingPrice
        : valor;
    data["paymentMethod"] = pagamento;
    data["evaluation"] = avaliacao;
    data["actionUser"] = context?.user.email || "unknown";
    data["actionDate"] = now;
    data["condutorEntrega"] = condutor;
    data["checkOut"] = now;
    data["action"] =
      estado === "entregue" ? "Entrega" : "Estado 'em entrega' ou 'pendente'";
    data["stats"] = estado;
    data[
      "carLocation"
    ] = `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`;

    try {
      if (avaliacao && estado === "entregue") await sendEmail(estado);
      updateClient(
        context,
        navigate,
        data,
        context?.user.id || "unknown",
        estado === "entregue" ? "Entrega" : "Estado 'em entrega' ou 'pendente'",
        openDialog
      );
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  async function sendEmail(estado: string) {
    async function getCity() {
      switch (client?.city) {
        case "faro":
          return "Faro";
        case "lisbon":
          return "Lisboa";
        case "porto":
          return "Porto";
        default:
          return "Lisboa";
      }
    }

    async function getLink() {
      switch (client?.parkBrand) {
        case "airpark":
          return "https://g.page/r/CfndpzbmBHy9EBM/review";
        case "redpark":
          return "https://g.page/r/CYjm1KzxzYTjEB0/review";
        case "skypark":
          return "em falta";
        default:
          return "https://g.page/r/CfndpzbmBHy9EBM/review";
      }
    }

    // Email params
    const emailParams = {
      email: client?.email,
      brand:
        client?.parkBrand.charAt(0).toUpperCase() + client?.parkBrand.slice(1),
      city: await getCity(),
      link: await getLink(),
    };

    if (
      client?.email &&
      client?.email !== "não@tem.pt" &&
      !client?.email.includes("não") &&
      !client?.email.includes("teste")
    )
      emailjs.send(
        "service_ccpv2ev",
        "template_6pvmahy",
        emailParams,
        "jeStFGZ-400kFvT_-"
      );
  }

  if (!client || !listaDePagamento) {
    return <Loading />;
  }

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          ENTREGA
        </h1>
        <Card href={null} client={client} type={"entregas"} />
        <div className="flex justify-center">
          <p>
            Reserva de: <span className="font-bold">{client?.parkBrand}</span>
          </p>
        </div>
        <div className="flex justify-between gap-4">
          <div className="w-[55%] flex flex-col justify-between gap-4">
            <p>Detalhes Carro:</p>
            <textarea
              className="border-black border-2 rounded-[15px] p-2 resize-none h-full bg-[rgba(.5,.5,.5,.1)]"
              disabled
              value={veiculo}
              onChange={(e) => setVeiculo(e.target.value)}
              placeholder="| Veiculo"
            />
          </div>
          <div className="flex flex-col w-full gap-4">
            <p>Local park:</p>
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={parque}
              onChange={(e) => setParque(e.target.value)}
              placeholder="| Parque"
            />
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={fila}
              onChange={(e) => setFila(e.target.value)}
              placeholder="| Fila"
            />
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={lugar}
              onChange={(e) => setLugar(e.target.value)}
              placeholder="| Lugar"
            />
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-col gap-2">
              <p>Data:</p>
              <input
                className="input bg-[rgba(.5,.5,.5,.1)]"
                disabled
                type="text"
                value={data}
                onChange={(e) => setData(e.target.value)}
                placeholder="| Hora"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p>Hora atual:</p>
              <input
                className="w-full border-2 border-black rounded-[12px] px-3 py-2 bg-[rgba(.5,.5,.5,.1)]"
                type="text"
                value={getCurrentHour()}
                disabled
                placeholder="| Hora"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p>Preço da reserva:</p>
              <input
                className="w-full border-2 border-black rounded-[12px] px-3 py-2 bg-[rgba(.5,.5,.5,.1)]"
                type="text"
                value={`${client?.bookingPrice}€`}
                disabled
                placeholder="| Hora"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p>Preço/Valor final:</p>
              <input
                className="w-full px-3 py-2 border-2 border-black rounded-[12px]"
                type="text"
                value={valor}
                onChange={(e) => setValor(e.target.value)}
                placeholder="| Valor"
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-col gap-2">
              <p>Hora:</p>
              <input
                className="input bg-[rgba(.5,.5,.5,.1)]"
                disabled
                type="text"
                value={hora}
                onChange={(e) => setHora(e.target.value)}
                placeholder="| Hora"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p>Condutor:</p>
              <input
                className="w-full px-3 py-2 border-2 border-black rounded-[12px]"
                disabled={context?.user.type !== "Admin"}
                type="text"
                value={condutor}
                onChange={(e) => setCondutor(e.target.value)}
                placeholder="| Condutor"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p>Preço recalculado:</p>
              <input
                className="w-full border-2 border-black rounded-[12px] px-3 py-2 bg-[rgba(.5,.5,.5,.1)]"
                type="text"
                value={recalculatePrice()}
                disabled
                placeholder="|"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p>Pagamento:</p>
              <select
                name="pagamento"
                value={pagamento}
                className="select"
                onChange={(e) => setPagamento(e.target.value)}
              >
                <option value="">Selecionar</option>
                {listaDePagamento
                  .slice()
                  .reverse()
                  .map((ele: string, index: number) => {
                    return (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        {client?.hasOnlinePayment && (
          <div className="flex">
            <p>
              <span className="text-red-500">*</span> Esta reserva foi paga
              online
            </p>
          </div>
        )}
        <div className="flex gap-8">
          <p>Avaliação:</p>
          <div>
            <input
              type="radio"
              value="yes"
              checked={avaliacao}
              onClick={() => setAvaliacao(true)}
              onChange={() => setAvaliacao(true)}
              className="w-[20px] h-[20px] accent-[var(--primary)]"
            />
            <label htmlFor="avalicaoYes" className="ml-2">
              Sim
            </label>
          </div>
          <div>
            <input
              type="radio"
              value="no"
              checked={!avaliacao}
              onClick={() => setAvaliacao(false)}
              onChange={() => setAvaliacao(false)}
              className="w-[20px] h-[20px] accent-[var(--primary)]"
            />
            <label htmlFor="avalicaoNo" className="ml-2">
              Não
            </label>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {client?.stats === "recolhido" && (
            <button
              onClick={() => entregar("pendente")}
              className="w-full text-[1rem] bg-[var(--yellow)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black"
            >
              PENDENTE
            </button>
          )}
          {(client?.stats === "recolhido" || client?.stats === "pendente") && (
            <button
              onClick={() => entregar("em entrega")}
              className="w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black"
            >
              EM ENTREGA
            </button>
          )}
          {(client?.stats === "recolhido" ||
            client?.stats === "em entrega") && (
            <button
              onClick={() => entregar("entregue")}
              className="w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black"
            >
              ENTREGAR
            </button>
          )}
          <button
            onClick={ocorrencia}
            className="w-full text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black"
          >
            OCORRÊNCIA
          </button>
          {context?.user?.type !== "Condutor" &&
            context?.user?.type !== "Junior" && (
              <button
                onClick={() => navigate(`/consulta/${client?.idClient}`)}
                className="w-full text-[1rem] bg-[var(--primary)] border-[1px] text-white hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black"
              >
                CONSULTAR
              </button>
            )}
        </div>
      </div>
      <Dialog
        message={dialogMessage}
        opened={dialogOpen}
        onDialog={closeDialog}
        onClose={dialogOnClose}
      />
    </>
  );
}
