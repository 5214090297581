import info from "./brands.json";

const PDFGenerator = ({
  client,
  lang = "pt",
}: {
  client: any;
  lang: string;
}) => {
  function getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    return `${dd}/${mm}/${yyyy}`;
  }

  const brands = info[client?.city.toLowerCase() as keyof typeof info];
  const brandInfo = brands[client?.parkBrand as keyof typeof brands];

  return (
    <div className="flex flex-col items-center pb-10 text-[1rem] h-screen relative">
      <div id="pdfContent" className="w-[65rem] px-[8rem]">
        <div className="flex items-center justify-center w-full mb-10">
          <img src={brandInfo?.image} alt="logo" className="w-[20rem]" />
        </div>
        <p className="mt-3 mb-5 text-center uppercase">
          {client?.city} {getCurrentDate()}
        </p>
        <div className="flex justify-between w-full">
          <div className="">
            <p className="mb-8 font-bold">
              {lang === "pt" ? "Alocação" : "Alocation"}:{" "}
              <span className="font-bold">{client?.alocation}</span>
            </p>
            <p className="font-bold">
              {lang === "pt" ? "Matrícula" : "License Plate"}: <br />{" "}
              <span className="text-[2rem] font-bold uppercase">
                {client?.licensePlate}
              </span>
            </p>
          </div>
          <div className="">
            <p className="mb-8 font-bold">
              PVP: <span className="font-bold">{client?.bookingPrice} €</span>
            </p>
            <div className="">
              <span className="font-bold">
                {lang === "pt" ? "Serviços Extra" : "Extra Services"}
              </span>
              : <br />{" "}
              <span>
                {client?.extraServices?.length !== 0 &&
                  client?.extraServices?.map((ele: any, index: number) => {
                    return (
                      <p key={index}>
                        {ele[lang]}
                        {index !== client?.extraServices.length - 1 && ","}
                      </p>
                    );
                  })}
              </span>
            </div>
          </div>
        </div>

        <div className="my-10 flex gap-24 text-[1.2rem]">
          <p className="font-bold">
            {lang === "pt" ? "Marca" : "Brand"}:{" "}
            <span className="font-medium">{client?.brand}</span>
          </p>
          <p className="font-bold">
            {lang === "pt" ? "Modelo" : "Model"}:{" "}
            <span className="font-medium">{client?.model}</span>
          </p>
          <p className="font-bold">
            {lang === "pt" ? "Cor" : "Color"}:{" "}
            <span className="font-medium">{client?.color}</span>
          </p>
        </div>

        <h2 className="my-5 font-bold uppercase text-[var(--primary)]">
          {lang === "pt" ? "Partida" : "Departure"}
        </h2>

        <div className="flex mb-4">
          <div className="mt-2 flex w-[25%] flex-col">
            <span className="font-bold uppercase">
              {lang === "pt" ? "Data" : "Date"}
            </span>
            <p>{client?.checkIn}</p>
          </div>

          <div className="mt-2 flex w-[25%] flex-col">
            <span className="font-bold uppercase">
              {lang === "pt" ? "Tipo de parque" : "Parking type"}
            </span>
            <p>
              {client?.parking
                ? client?.parking[lang] || client?.parkingType
                : ""}
            </p>
          </div>

          <div className="mt-2 flex w-[25%] flex-col">
            <span className="font-bold uppercase">
              {lang === "pt" ? "LOCAL DE ENTREGA" : "Delivery Point"}
            </span>
            <p>
              {client?.delivery
                ? client?.delivery[lang] || client?.deliveryName
                : ""}
            </p>
          </div>
        </div>

        <h2 className="my-5 font-bold uppercase text-[var(--primary)]">
          {lang === "pt" ? "Regresso" : "Return"}
        </h2>

        <div className="flex">
          <div className="mt-2 flex w-[25%] flex-col">
            <span className="font-bold uppercase">
              {lang === "pt" ? "Data" : "Date"}
            </span>
            <p>{client?.checkOut}</p>
          </div>

          <div className="flex flex-col w-1/2 mt-2">
            <span className="font-bold uppercase">
              {lang === "pt"
                ? "Nº VOO / CIDADE DE REGRESSO"
                : "Flight number / Return city"}
            </span>
            <p>{client?.returnFlight}</p>
          </div>
        </div>

        <div>
          <p className="mt-10 font-bold">
            {lang === "pt" ? "Partida" : "Departure"}:
          </p>
          <p className="text-sm">
            {lang === "pt"
              ? `Li e aceito os termos e condições gerais apresentadas em ${brandInfo?.url}. Autorizo que seja realizada uma reportagem videográfica à minha viatura com o intuito de registar os danos presentes na mesma. Autorizo que a minha viatura seja transportada por um funcionário do ${brandInfo?.name}, do Aeroporto até às suas instalações e vice-versa. Durante esse percurso o seguro usado será o da viatura. Qualquer sinistro ou dano ocorrido dentro das instalações do ${brandInfo?.name} será da responsabilidade do mesmo, à exceção de objetos de valor deixados dentro da viatura, peças de desgaste, problemas mecânicos e vidros`
              : `I have read and accept the general terms and conditions presented at ${brandInfo?.url}. I authorize a video report to be made of my vehicle in order to record the damages present in it. I authorize my vehicle to be transported by an employee of ${brandInfo?.name}, from the Airport to its facilities and vice versa. During this journey the insurance used will be that of the vehicle. Any accident or damage that occurs within the facilities of ${brandInfo?.name} will be the responsibility of the same, except for valuables left inside the vehicle, wear parts, mechanical problems and glass`}
          </p>
        </div>

        <div className="mt-10">
          <p className="font-bold">{lang === "pt" ? "Regresso" : "Return"}:</p>
          <p className="text-sm">
            {lang === "pt"
              ? `Declaro que recebi a minha viatura nas mesmas condições em que a entreguei à empresa ${brandInfo?.name}, terminando assim o contrato estabelecido com a mesma.`
              : `I declare that I have received my vehicle in the same condition in which I delivered it to the company ${brandInfo?.name}, thus terminating the contract established with them.`}
          </p>
        </div>

        <div className="flex w-full mt-10 justify-evenly">
          <div className="flex flex-col items-center text-center">
            <p>
              {lang === "pt" ? "Assinatura de Partida" : "Departure Signature"}
            </p>
            <div className="border-b-2 border-black mt-12 w-[10rem]"></div>
            <p>
              ({client?.name} {client?.lastname})
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <p>
              {lang === "pt" ? "Assinatura de Regresso" : "Return Signature"}
            </p>
            <div className="border-b-2 border-black mt-12 w-[10rem]"></div>
            <p>
              ({client?.name} {client?.lastname})
            </p>
          </div>
        </div>

        <div className="absolute left-0 flex justify-center w-full text-sm font-normal bottom-2">
          <p>
            Phone: {brandInfo?.contact} | E-mail: {brandInfo?.email} | Web:{" "}
            {brandInfo?.url}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PDFGenerator;
